import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <img src='/logo.png' {...props} style={{marginRight:'0.3rem'}} />
      /*<Svg {...props} x={0} y={0} viewBox="0 0 575.24 575.24">
          <circle fill="#06C7FE" cx="283.87" cy="317.01" r="232.06" />
          <circle fill="#57DFFF" cx="299.2" cy="327.96" r="178.42" />
          <circle fill="#B6F1FF" cx="337.51" cy="354.23" r="133.54" />
          <circle fill="#E1F9FF" cx="368.16" cy="380.5" r="94.14" />
          <circle fill="#FFFFFF" cx="393.33" cy="402.39" r="61.3" />
          <g>
              <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="66.6012"
                  y1="401.0543"
                  x2="538.9536"
                  y2="206.5308"
              >
                  <stop offset="0" style={{stopColor:"#00DAFE"}} />
                  <stop offset="0.4721" style={{stopColor:"#2669FE"}} />
                  <stop offset="1" style={{stopColor:"#7763F9"}} />
              </linearGradient>
              <path
                  fill="url(#SVGID_1_)"
                  d="M387.47,80.93c-3.05-11.3-15.6-52.25-42.61-75.89c0,0-23.66,47.32-49.3,55.21l0.78,0.2
		c-3.21-0.12-6.42-0.2-9.66-0.2c-141.03,0-255.35,114.33-255.35,255.35s114.33,255.35,255.35,255.35s255.35-114.33,255.35-255.35
		C542.04,210.37,478.38,120.02,387.47,80.93z M340.91,48.42c9.86,11.83,3.94,45.35,3.94,45.35c0-9.86-27.61-11.83-27.61-11.83
		C333.03,68.14,340.91,48.42,340.91,48.42z M431.62,432.93c0,0-3.94,11.83-29.58,13.8c-25.63,1.97-76.9-5.92-100.56,17.75
		s-37.46,49.3-37.46,78.87c0,0-191.14-5.92-208.95-227.75c0,0-5.98-182.4,195.15-227.75c0,0-23.66,21.69-33.52,39.44
		c-9.86,17.75-17.75,49.3-82.82,74.93c0,0-43.38,9.86-47.32,27.61c-3.94,17.75,13.8,27.61,15.77,29.58
		c1.97,1.97,9.84,27.61,40.41,35.49c0,0,119.31,55.21,194.24-25.63c0,0,13.8,41.41,27.61,49.3c13.8,7.89,35.49,5.92,49.3,27.61
		s15.77,31.55,29.58,43.38C457.25,401.38,459.22,419.13,431.62,432.93z"
              />
              <path
                  fill="3468FD"
                  d="M325.14,324.48c5.92-7.89,21.69-5.92,21.69-5.92c-7.89-7.89-9.32-23.66-9.32-23.66
		C320.3,304.76,325.14,324.48,325.14,324.48z"
              />
          </g>
      </Svg>*/
  );
};

export default Icon;
